document.addEventListener('DOMContentLoaded', function () {
	var splide = new Splide('.splide', {
		type: 'fade',
		perPage: 1,
		autoplay: false,
		interval: 5000,
		pagination: false,
		arrows: true,
		breakpoints: {
			1024: {
				arrows: false,
				pagination: false,
			},
			992: {
				arrows: false,
				pagination: true,
			},
		}
	}).mount();

	var youtubeVideos = document.querySelectorAll('.youtube-video iframe');

	splide.on('moved', function (newIndex) {
		for (var i = 0; i < youtubeVideos.length; i++) {
			if (i !== newIndex) {
				var videoSrc = youtubeVideos[i].src;
				youtubeVideos[i].src = ''; // Pausa el video
				youtubeVideos[i].src = videoSrc; // Restablece el video
			}
		}
	});
});

document.addEventListener("DOMContentLoaded", function () {
	window.addEventListener('scroll', function () {
		if (window.scrollY > 30) {
			const newLocal = 'legend';
			document.getElementById(newLocal).classList.add('fixed-top');
			// add padding top to show content behind navbar
			//navbar_height = document.querySelector('.navbar').offsetHeight;
		} else {
			document.getElementById('legend').classList.remove('fixed-top');
			// remove padding top from body
		}
	});
});

/* SCROLL BOTON DE WHATSAPP */
window.onscroll = function() { stickyWhatsapp() };

function stickyWhatsapp() {
    const btnWhatsapp = document.getElementById("stickyWhatsapp");
    const footer = document.getElementById("footer");

    if (window.scrollY > 0) {
      btnWhatsapp.classList.add("scrolled");
    } else {
      btnWhatsapp.classList.remove("scrolled");
    }

    // Obtener la posición del footer
    const footerPosition = footer.getBoundingClientRect().top;

    // Verificar si el footer está en la pantalla
    if (footerPosition <= window.innerHeight) {
      btnWhatsapp.classList.add("reached-footer");
    } else {
      btnWhatsapp.classList.remove("reached-footer");
    }
  }




$(() => {

	$(".js-modal-btn").modalVideo();

	AOS.init({
		disable: 'mobile',
		once: true,
	});

	$('.counter').counterUp({
		delay: 10,
		time: 800
	});

	$(".nav-link").click(function (event) {
		event.preventDefault();
		var target = $(this).attr('href');

		$('html, body').animate({
			scrollTop: $(target).offset().top - 100
		}, 0);
	});

	$('.figureCont').hover(
		function() {
			// Mouseover
			//$(this).find('.figureText').fadeIn();
			$(this).find('.figureText').addClass('showText');
		},
		function() {
			// Mouseout
			$(this).find('.figureText').removeClass('showText');
		}
	);



	function carouselProy() {

		var slider = $('#carousel-projects');




		/*slider.slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						arrows: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						dots: true
					}
				}
			]
		});*/


		$('#carousel-propiedades').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 1500,
			responsive: [
				{
					breakpoint: 1220,
					settings: {
						slidesToShow: 2,
						arrows: false
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						arrows: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						arrows: false,
						dots: true
					}
				}
			],
		});

		$('#carousel-agentes').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			arrows: true,
			dots: false,
			autoplaySpeed: 1500,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						arrows: false,
					}
				}
			],
		});

	};

	carouselProy();


	function validateForm() {
		// Accept only figure from 0 to 9 and + ( ) in the phone field
		$(".js-form input[name='phone'").keyup(function() {
			$("input[name='phone'").val(this.value.match(/[0-9 + ( )]*/));
		});

		$("#form").validate({
			rules: {
				firstname: 'required',
				lastname: 'required',
				email: { required: true, email: true },
				phone: { required: true },
				distrito: 'required',
			},
			messages: {
				firstname: "Campo obligatorio",
				lastname: "Campo obligatorio",
				email: {
					required: "Campo obligatorio",
					email: "Ingresar una dirección de correo válida",
				},
				phone: "Campo obligatorio",
				distrito: "Campo obligatorio",
			},
			submitHandler: function (form) {
				$.ajax({
					url: "/action.php",
					dataType: "json",
					method: "post",
					data: $(form).serialize(),
					success: function (data) {
						$('button[type="submit"]').prop('disabled', false);

						var ResultadoResp = data.response;

						if (ResultadoResp == '0') {
						}
						if (ResultadoResp == '1') {
							window.location.href = "gracias.php";
						}
					},
					beforeSend: function () {
						$('button[type="submit"]').prop('disabled', true);
					}
				});
			}
		});

		$("#formBanner").validate({
			rules: {
				firstname: 'required',
				lastname: 'required',
				email: { required: true, email: true },
				phone: { required: true },
				distrito: 'required',
			},
			messages: {
				firstname: "Campo obligatorio",
				lastname: "Campo obligatorio",
				email: {
					required: "Campo obligatorio",
					email: "Ingresar una dirección de correo válida",
				},
				phone: "Campo obligatorio",
				distrito: "Campo obligatorio",
			},
			submitHandler: function (form) {
				$.ajax({
					url: "/action.php",
					dataType: "json",
					method: "post",
					data: $(form).serialize(),
					success: function (data) {
						$('button[type="submit"]').prop('disabled', false);

						var ResultadoResp = data.response;

						if (ResultadoResp == '0') {
						}
						if (ResultadoResp == '1') {
							window.location.href = "gracias.php";
						}
					},
					beforeSend: function () {
						$('button[type="submit"]').prop('disabled', true);
					}
				});
			}
		});
	}
		validateForm();

	});

	

	// Función para obtener los parámetros UTM de la URL
	function getUTMParams() {
		const params = new URLSearchParams(window.location.search);
		return {
			utm_source: params.get('utm_source'),
			utm_medium: params.get('utm_medium'),
			utm_campaign: params.get('utm_campaign'),
			utm_content: params.get('utm_content')
		};
	}

	// Almacenar los parámetros en el almacenamiento local para usarlos más tarde
	function storeUTMParams() {
		const utmParams = getUTMParams();
		if (utmParams.utm_source) {
			localStorage.setItem('utm_source', utmParams.utm_source);
		}
		if (utmParams.utm_medium) {
			localStorage.setItem('utm_medium', utmParams.utm_medium);
		}
		if (utmParams.utm_campaign) {
			localStorage.setItem('utm_campaign', utmParams.utm_campaign);
		}
		if (utmParams.utm_content) {
			localStorage.setItem('utm_content', utmParams.utm_content);
		}
	}

	// Ejecutar cuando la página se carga
	window.onload = function() {
		storeUTMParams();
	};

	// Función para prellenar los campos del formulario con UTM
	function prefillForm() {
		document.querySelector('input[name="utm_source"]').value = localStorage.getItem('utm_source') || '';
		document.querySelector('input[name="utm_medium"]').value = localStorage.getItem('utm_medium') || '';
		document.querySelector('input[name="utm_campaign"]').value = localStorage.getItem('utm_campaign') || '';
		document.querySelector('input[name="utm_content"]').value = localStorage.getItem('utm_content') || '';
	}

	// Llamar a la función al cargar el formulario
	document.addEventListener('DOMContentLoaded', function() {
		prefillForm();
	});

	